@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  background: transparent url(/static/media/collab-dark.294731b2.png) 0% 0% no-repeat padding-box;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;
  margin: 1% 5% 0% 5%;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  margin: 1% 5% 0% 5%;
}

.contactEmail {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.titleContainer {
  border: 1px solid #93CAEB;
  padding: 0px 20px 0px 20px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
  font-size: 43px;
  margin: 0;
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
}

.logoContainer {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1;
}

.scrollIcon {
  height: 58px;
  width: 58px;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 2%;
}

.arrowDownIcon {
  margin-top: 5px;
}

.scrollText {
  font-size: 10px;
}

.secondaryTitle {
  text-align: center;
  font: normal normal 300 15px/31px Poppins;
  letter-spacing: 0.3px;
  color: #A3A0A0;
  opacity: 1;
}

.menuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menuIcon {
  color: #93CAEB;
}

.menuTextHomepage {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
  margin-right: 10px;
}

.navigationButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.navigationButton {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  margin-right: 10px;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.navigationButtonsDelimiter {
  font-family: 'Raleway', sans-serif;
  font-size: 10px;
  margin-right: 10px;
}

.facebookIcon {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 430px) {
  .contentWrapper {
    flex-direction: column;
  }

  .left {
    flex-direction: row;
    padding: 10px;
    height: 40px;
    width: 90%;
    align-items: center;
    margin: 0;
  }

  .middle {
    flex-direction: column;
    margin-top: 0;
  }

  .right {
    padding: 0;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
    height: 13%;
    width: 90%;
  }

  .navigationButton {
    margin: 0;
  }

  .navigationButtonsDelimiter {
    margin: 0;
  }

  .titles {
    padding: 20px;
    margin-top: 20px;
  }

  .title {
    font-size: x-large;
  }

  .navigationButtonsContainer {
    margin-bottom: 0;
  }
}

.slideAnimation {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.socialContainer {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}

.facebookIcon {
  fill: white;
  height: 15px;
  width: 15px;
}

.twitterIcon {
  fill: white;
  height: 15px;
  width: 15px;
}

@media screen and (max-width: 430px) {
  .socialContainer {
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .facebookIcon {
    margin-right: 20px;
  }
}
.container1 {
  height: 100vh;
  background-image: url(/static/media/Image.23a9586d.png);
  background-position: right;
  background-repeat: no-repeat;
}

.topLevelContainer {
  /* max-width: 1440px; */
  margin: 0 auto;
}
.container2 {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url(/static/media/Image.23a9586d.png);
  background-position: right;
  background-repeat: no-repeat;
  margin-top: 55px;
}

.container2Left {
  text-align: left;
  flex: 1 1;
  width: 50%;
  margin-left: 11%;
  margin-top: 10%;
}

.container2Right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.theSolutionFirstTitle {
  font: normal normal 600 12px/34px Poppins;
  letter-spacing: 0.72px;
  color: #FFFFFF;
  margin: 0;
}

.theSolutionSecondTitle {
  font-family: 'Poppins';
  font-size: 35px;
  letter-spacing: -0.88px;
  color: #FFFFFF;
  margin: 0;
  text-align: left;
}

.theSolutionContent {
  text-align: left;
  font: normal normal 300 16px/28px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  max-width: 700px;
  margin-top: 50px;
  max-height: 252px;
  overflow: hidden;

}

@media screen and (max-width: 430px) {
  .container2 {
    flex-direction: column;
    align-items: center;
    height: auto;
    /* justify-content: center; */
  }

  .container2Left {
    margin: 0;
    margin-top: 14%;
    width: auto;
    padding: 5%;
  }

  .container2Right {
    overflow: hidden;
  }

  .mobileImage {
    max-height: 100%;
    max-width: 100%;
  }

  .theSolutionContent {
    margin-top: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .theSolutionFirstTitle {
    font-size: 19px;
  }

  .theSolutionSecondTitle {
    font-size: 42px;
  }

  .theSolutionContent {
    font-size: 23px;
  }
}
#team_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  z-index: 999;
  font-family: 'Poppins';
}

#team_modal .slider {
  padding: 20px;
  position: relative;
  min-width: 300px;
  max-width: 650px;
  text-align: center;
  overflow: hidden;
}
#team_modal .slider #close_modal {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
}
#team_modal .slides {
  margin-top: 15px;
  display: flex;
  overflow-x: hidden;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#team_modal .slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}
#team_modal .slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
#team_modal .slides::-webkit-scrollbar-track {
  background: transparent;
}
#team_modal .slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  margin-right: 50px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
}
#team_modal .slides > div > p {
  white-space: pre-wrap;
}
#team_modal .slider .avatar {
  display: inline-flex;
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 10px;
  transition: 0.2s;
}
#team_modal .slider .avatar:active,
#team_modal .slider .avatar.active {
  width: 90px;
  height: 90px;
  transform: translate(-5px, -5px);
}
#team_modal .slider .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
#team_modal .slider .avatar:active {
  top: 1px;
}
#team_modal .slider .avatar:focus {
  background: #000;
}
#team_modal #team_modal_content {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  color: #011329;
  max-height: 90vh;
  overflow: auto;
}

.teamModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.container3 {
  height: 100vh;
  margin-left: 204px;
  margin-top: 10%;
  margin-left: 10%;
}

.container3Upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
}

.container3Lower {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.theTeamTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #131313;
}

.memberCard {
  min-width: 23%;
  padding-right: 8%;
}


.avatarLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.avatar {
  height: 102px;
  width: 102px;
}

.linkedInIcon {
  fill: #000000;
  background-color: black;
  border-radius: 30px;
  height: 37px;
  width: 37px;
}

.memberInfoContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.memberName {
  font-family: 'Poppins';
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #131313;
  margin-bottom: 0;
}

.memberPosition {
  margin-top: 0;
  text-align: left;
  font: normal normal 300 14px/28px Poppins;
  letter-spacing: 0px;
  color: #5A5A5A;
}

.memberAbout {
  max-height: 160px;
  overflow: hidden;
  text-align: left;
  font: normal normal 300 14px/23px Poppins;
  letter-spacing: 0px;
  color: #131313;
}

@media screen and (max-width: 430px) {
  .container3 {
    margin: 20px;
    height: auto;
  }

  .container3Lower {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .memberCard {
    padding: 0;
  }
}

.modalText {
  font: normal normal 300 14px/23px Poppins;
}

.dotsButton {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.carouselContainer {
  width: 100%;
}

@media screen and (min-width: 2000px) {
  .theTeamTitle {
    font-size: 42px;
  }

  .memberName {
    font-size: 28px;
  }

  .memberPosition {
    font-size: 21px;
  }

  .memberAbout {
    font-size: 21px;
  }
}
.container4 {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;

}

.container4Lower {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  margin-left: 10%;
  width: 75%;
}

.container4Upper {
  margin-left: 10%;
}

.partnersTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #FFFFFF;
}

.logoBox {
  height: 100%;
  width: 100%;
  border: 1px solid #292929;
  display: flex;
  flex-direction: unset;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 430px) {
  .container4 {
    height: auto;
    padding: 5%;
    align-items: center;
  }

  .partnersTitle {
    align-self: flex-start;
  }

  .container4Lower {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0;
  }

  .container4Upper {
    margin: 0;
  }
}

@media screen and (min-width: 2000px) {
  .partnersTitle {
    font-size: 42px;
  }
}
.container5 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10%;
  margin-top: 10%;

  background-image: url(/static/media/Image.23a9586d.png);
  background-position: top;
  background-repeat: no-repeat;
}

.container5Lower {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: 70%;
}

.newsTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #131313;
}

.newsElementContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 75%;
  height: auto;
  overflow: hidden;
}

.newsElementDate {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.72px;
  color: #131313;
  width: 82px;
  white-space: nowrap;
}

.newsElementTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #131313;
  margin: 0;
}

.newsElementContent {
  margin: 0;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #131313;
}

.newsElementText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  text-align: left;
}

.readMoreLink {
  font-family: 'Poppins';
  font-size: 14px;
  color: #91C9EA;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 3%;
}

@media screen and (max-width: 430px) {
  .container5 {
    margin: 10%;
    margin-bottom: 20%;
  }

  .newsElementContainer {
    flex-direction: column;
    width: 80%;
    height: auto;
  }

  .newsElementTitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .container5 {
    height: auto;
  }
}

@media screen and (min-width: 2000px) {
  .container5Lower {
    height: 80%;
  }

  .newsTitle {
    font-size: 42px;
  }

  .newsElementDate {
    font-size: 19px;
    margin-right: 5%;
  }

  .newsElementTitle {
    font-size: 31px;
  }

  .newsElementContent {
    font-size: 21px;
  }
}
.container1 {
  height: 100vh;
  background-image: url(/static/media/Image.23a9586d.png);
  background-position: right;
  background-repeat: no-repeat;
  margin-top: 55px;
}

.advantageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11%;
  margin-top: 10%;
  height: 78%;
}

.advantageFirstTitle {
  font: normal normal 600 12px/34px Poppins;
  letter-spacing: 0.72px;
  color: #626262;
  margin: 0;
}

.advantageSecondTitle {
  font-family: 'Poppins';
  font-size: 35px; 
  letter-spacing: -0.88px;
  color: #131313;
  margin: 0;
  text-align: left;
}

.advantageContent {
  max-width: 700px;
  color: #131313;
  margin-top: 50px;
  text-align: left;
  font: normal normal 300 15px/28px Poppins;
  letter-spacing: 0px;
}

.advantageNavigationButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-right: 20px;
  margin-top: auto;
}

.advantageNavigationButton {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  margin-right: 10px;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #000000;
}

@media screen and (max-width: 430px) {
  .advantageContainer {
    margin: 0;
    padding: 20px;
  }

  .container1 {
    height: auto;
    margin-top: 17%;
  }

  .advantageNavigationButton {
    margin-right: 0;
  }
}

@media screen and (min-width: 2000px) {
  .advantageFirstTitle {
    font-size: 19px;
  }

  .advantageSecondTitle {
    font-size: 42px;
  }

  .advantageContent {
    font-size: 22px;
    max-width: 50%;
  }

}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
  position: fixed;
  top: 0;
  background-color: white;
  width: 99%;
  z-index: 10;
}

.headerButton {
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: bold;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-right: 10px;
  letter-spacing: 0.72px;
  color: #5A5A5A;
}

.headerButtonMobile {
  text-align: center;
  font: normal normal bold 30px/34px Raleway;
  letter-spacing: 1.8px;
  color: #FFFFFF;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-bottom: 10%;
}

.collabgisticsLogo {
  height: 50px;
  margin-right: auto;
  opacity: 1;
  cursor: pointer;
}

.headerButtonsContainerMobile {
  display: none;
}

.blueButton {
  color: #91C9EA;
}

.menuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menuIcon {
  color: #93CAEB;
}

.menuText {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: black;
  margin-right: 10px;
}

@media screen and (max-width: 940px) {
  .headerButtonsContainer {
    display: none;
  }

  .headerButtonsContainerMobile {
    display: flex;
  }
}

@media screen and (max-width: 430px) {
  .header {
    padding: 0;
    width: 100%;
  }

  .collabgisticsLogo {
    height: 30px;
    max-width: 35%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .headerButton {
    /* display: none; */
    margin: 0;
  }
}

.contactContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F8F8F8 0% 0% no-repeat padding-box;
}

.contactHeadings {
  margin-top: 10%;
  margin-bottom: 10%;
  max-width: 80%;
}

.contactTitle {
  text-align: center;
  /* font: normal normal medium 35px/34px Poppins; */
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 300;
  /* font-weight: medium; */
  letter-spacing: -0.88px;
  color: #131313;
}

.contactSecondaryTitle {
  text-align: center;
  font: normal normal 300 15px/28px Poppins;
  letter-spacing: 0px;
  color: #131313;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  text-align: center;
}

.contactElement {
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 30%;
}

.contactElementTitle {
  font-family: 'Poppins';
  font-weight: 200;
}

.contactElementContent {
  font-family: 'Poppins';
  font-weight: 100;
}

@media screen and (max-width: 430px) {

  .contactContainer {
    height: auto;
  }

  .infoContainer {
    flex-direction: column;
  }

  .contactElement {
    margin-right: 0;
    margin-bottom: 20%;
  }

  .contactElementContent {
    margin: 0;
  }
}

@media screen and (min-width: 2000px) {
  .contactTitle {
    font-size: 42px;
  }

  .contactSecondaryTitle {
    font-size: 22px;
  }
}
