.container2 {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url('../../img/Image.png');
  background-position: right;
  background-repeat: no-repeat;
  margin-top: 55px;
}

.container2Left {
  text-align: left;
  flex: 1;
  width: 50%;
  margin-left: 11%;
  margin-top: 10%;
}

.container2Right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.theSolutionFirstTitle {
  font: normal normal 600 12px/34px Poppins;
  letter-spacing: 0.72px;
  color: #FFFFFF;
  margin: 0;
}

.theSolutionSecondTitle {
  font-family: 'Poppins';
  font-size: 35px;
  letter-spacing: -0.88px;
  color: #FFFFFF;
  margin: 0;
  text-align: left;
}

.theSolutionContent {
  text-align: left;
  font: normal normal 300 16px/28px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  max-width: 700px;
  margin-top: 50px;
  max-height: 252px;
  overflow: hidden;

}

@media screen and (max-width: 430px) {
  .container2 {
    flex-direction: column;
    align-items: center;
    height: auto;
    /* justify-content: center; */
  }

  .container2Left {
    margin: 0;
    margin-top: 14%;
    width: auto;
    padding: 5%;
  }

  .container2Right {
    overflow: hidden;
  }

  .mobileImage {
    max-height: 100%;
    max-width: 100%;
  }

  .theSolutionContent {
    margin-top: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .theSolutionFirstTitle {
    font-size: 19px;
  }

  .theSolutionSecondTitle {
    font-size: 42px;
  }

  .theSolutionContent {
    font-size: 23px;
  }
}