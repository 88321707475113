.contactContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F8F8F8 0% 0% no-repeat padding-box;
}

.contactHeadings {
  margin-top: 10%;
  margin-bottom: 10%;
  max-width: 80%;
}

.contactTitle {
  text-align: center;
  /* font: normal normal medium 35px/34px Poppins; */
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 300;
  /* font-weight: medium; */
  letter-spacing: -0.88px;
  color: #131313;
}

.contactSecondaryTitle {
  text-align: center;
  font: normal normal 300 15px/28px Poppins;
  letter-spacing: 0px;
  color: #131313;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  text-align: center;
}

.contactElement {
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 30%;
}

.contactElementTitle {
  font-family: 'Poppins';
  font-weight: 200;
}

.contactElementContent {
  font-family: 'Poppins';
  font-weight: 100;
}

@media screen and (max-width: 430px) {

  .contactContainer {
    height: auto;
  }

  .infoContainer {
    flex-direction: column;
  }

  .contactElement {
    margin-right: 0;
    margin-bottom: 20%;
  }

  .contactElementContent {
    margin: 0;
  }
}

@media screen and (min-width: 2000px) {
  .contactTitle {
    font-size: 42px;
  }

  .contactSecondaryTitle {
    font-size: 22px;
  }
}