#team_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  z-index: 999;
  font-family: 'Poppins';
}

#team_modal .slider {
  padding: 20px;
  position: relative;
  min-width: 300px;
  max-width: 650px;
  text-align: center;
  overflow: hidden;
}
#team_modal .slider #close_modal {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
}
#team_modal .slides {
  margin-top: 15px;
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#team_modal .slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}
#team_modal .slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
#team_modal .slides::-webkit-scrollbar-track {
  background: transparent;
}
#team_modal .slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  margin-right: 50px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
}
#team_modal .slides > div > p {
  white-space: pre-wrap;
}
#team_modal .slider .avatar {
  display: inline-flex;
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 10px;
  transition: 0.2s;
}
#team_modal .slider .avatar:active,
#team_modal .slider .avatar.active {
  width: 90px;
  height: 90px;
  transform: translate(-5px, -5px);
}
#team_modal .slider .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
#team_modal .slider .avatar:active {
  top: 1px;
}
#team_modal .slider .avatar:focus {
  background: #000;
}
#team_modal #team_modal_content {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  color: #011329;
  max-height: 90vh;
  overflow: auto;
}

.teamModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}
