.container4 {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;

}

.container4Lower {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  margin-left: 10%;
  width: 75%;
}

.container4Upper {
  margin-left: 10%;
}

.partnersTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #FFFFFF;
}

.logoBox {
  height: 100%;
  width: 100%;
  border: 1px solid #292929;
  display: flex;
  flex-direction: unset;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 430px) {
  .container4 {
    height: auto;
    padding: 5%;
    align-items: center;
  }

  .partnersTitle {
    align-self: flex-start;
  }

  .container4Lower {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0;
  }

  .container4Upper {
    margin: 0;
  }
}

@media screen and (min-width: 2000px) {
  .partnersTitle {
    font-size: 42px;
  }
}