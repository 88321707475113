.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
  position: fixed;
  top: 0;
  background-color: white;
  width: 99%;
  z-index: 10;
}

.headerButton {
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: bold;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-right: 10px;
  letter-spacing: 0.72px;
  color: #5A5A5A;
}

.headerButtonMobile {
  text-align: center;
  font: normal normal bold 30px/34px Raleway;
  letter-spacing: 1.8px;
  color: #FFFFFF;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-bottom: 10%;
}

.collabgisticsLogo {
  height: 50px;
  margin-right: auto;
  opacity: 1;
  cursor: pointer;
}

.headerButtonsContainerMobile {
  display: none;
}

.blueButton {
  color: #91C9EA;
}

.menuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menuIcon {
  color: #93CAEB;
}

.menuText {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: black;
  margin-right: 10px;
}

@media screen and (max-width: 940px) {
  .headerButtonsContainer {
    display: none;
  }

  .headerButtonsContainerMobile {
    display: flex;
  }
}

@media screen and (max-width: 430px) {
  .header {
    padding: 0;
    width: 100%;
  }

  .collabgisticsLogo {
    height: 30px;
    max-width: 35%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .headerButton {
    /* display: none; */
    margin: 0;
  }
}
