.container3 {
  height: 100vh;
  margin-left: 204px;
  margin-top: 10%;
  margin-left: 10%;
}

.container3Upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
}

.container3Lower {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.theTeamTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #131313;
}

.memberCard {
  min-width: 23%;
  padding-right: 8%;
}


.avatarLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.avatar {
  height: 102px;
  width: 102px;
}

.linkedInIcon {
  fill: #000000;
  background-color: black;
  border-radius: 30px;
  height: 37px;
  width: 37px;
}

.memberInfoContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.memberName {
  font-family: 'Poppins';
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #131313;
  margin-bottom: 0;
}

.memberPosition {
  margin-top: 0;
  text-align: left;
  font: normal normal 300 14px/28px Poppins;
  letter-spacing: 0px;
  color: #5A5A5A;
}

.memberAbout {
  max-height: 160px;
  overflow: hidden;
  text-align: left;
  font: normal normal 300 14px/23px Poppins;
  letter-spacing: 0px;
  color: #131313;
}

@media screen and (max-width: 430px) {
  .container3 {
    margin: 20px;
    height: auto;
  }

  .container3Lower {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .memberCard {
    padding: 0;
  }
}

.modalText {
  font: normal normal 300 14px/23px Poppins;
}

.dotsButton {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.carouselContainer {
  width: 100%;
}

@media screen and (min-width: 2000px) {
  .theTeamTitle {
    font-size: 42px;
  }

  .memberName {
    font-size: 28px;
  }

  .memberPosition {
    font-size: 21px;
  }

  .memberAbout {
    font-size: 21px;
  }
}