.container5 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10%;
  margin-top: 10%;

  background-image: url('../../img/Image.png');
  background-position: top;
  background-repeat: no-repeat;
}

.container5Lower {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 70%;
}

.newsTitle {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #131313;
}

.newsElementContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 75%;
  height: auto;
  overflow: hidden;
}

.newsElementDate {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.72px;
  color: #131313;
  width: 82px;
  white-space: nowrap;
}

.newsElementTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #131313;
  margin: 0;
}

.newsElementContent {
  margin: 0;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #131313;
}

.newsElementText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  text-align: left;
}

.readMoreLink {
  font-family: 'Poppins';
  font-size: 14px;
  color: #91C9EA;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 3%;
}

@media screen and (max-width: 430px) {
  .container5 {
    margin: 10%;
    margin-bottom: 20%;
  }

  .newsElementContainer {
    flex-direction: column;
    width: 80%;
    height: auto;
  }

  .newsElementTitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .container5 {
    height: auto;
  }
}

@media screen and (min-width: 2000px) {
  .container5Lower {
    height: 80%;
  }

  .newsTitle {
    font-size: 42px;
  }

  .newsElementDate {
    font-size: 19px;
    margin-right: 5%;
  }

  .newsElementTitle {
    font-size: 31px;
  }

  .newsElementContent {
    font-size: 21px;
  }
}