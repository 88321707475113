.container1 {
  height: 100vh;
  background-image: url('../../img/Image.png');
  background-position: right;
  background-repeat: no-repeat;
  margin-top: 55px;
}

.advantageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11%;
  margin-top: 10%;
  height: 78%;
}

.advantageFirstTitle {
  font: normal normal 600 12px/34px Poppins;
  letter-spacing: 0.72px;
  color: #626262;
  margin: 0;
}

.advantageSecondTitle {
  font-family: 'Poppins';
  font-size: 35px; 
  letter-spacing: -0.88px;
  color: #131313;
  margin: 0;
  text-align: left;
}

.advantageContent {
  max-width: 700px;
  color: #131313;
  margin-top: 50px;
  text-align: left;
  font: normal normal 300 15px/28px Poppins;
  letter-spacing: 0px;
}

.advantageNavigationButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-right: 20px;
  margin-top: auto;
}

.advantageNavigationButton {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  margin-right: 10px;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #000000;
}

@media screen and (max-width: 430px) {
  .advantageContainer {
    margin: 0;
    padding: 20px;
  }

  .container1 {
    height: auto;
    margin-top: 17%;
  }

  .advantageNavigationButton {
    margin-right: 0;
  }
}

@media screen and (min-width: 2000px) {
  .advantageFirstTitle {
    font-size: 19px;
  }

  .advantageSecondTitle {
    font-size: 42px;
  }

  .advantageContent {
    font-size: 22px;
    max-width: 50%;
  }

}