.socialContainer {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}

.facebookIcon {
  fill: white;
  height: 15px;
  width: 15px;
}

.twitterIcon {
  fill: white;
  height: 15px;
  width: 15px;
}

@media screen and (max-width: 430px) {
  .socialContainer {
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .facebookIcon {
    margin-right: 20px;
  }
}