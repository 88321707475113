.container {
  background: transparent url('../../img/collab-dark.png') 0% 0% no-repeat padding-box;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;
  margin: 1% 5% 0% 5%;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  margin: 1% 5% 0% 5%;
}

.contactEmail {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.titleContainer {
  border: 1px solid #93CAEB;
  padding: 0px 20px 0px 20px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
  font-size: 43px;
  margin: 0;
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.logoContainer {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}

.scrollIcon {
  height: 58px;
  width: 58px;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 2%;
}

.arrowDownIcon {
  margin-top: 5px;
}

.scrollText {
  font-size: 10px;
}

.secondaryTitle {
  text-align: center;
  font: normal normal 300 15px/31px Poppins;
  letter-spacing: 0.3px;
  color: #A3A0A0;
  opacity: 1;
}

.menuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menuIcon {
  color: #93CAEB;
}

.menuTextHomepage {
  text-align: left;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
  margin-right: 10px;
}

.navigationButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.navigationButton {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  margin-right: 10px;
  font: normal normal bold 10px/34px Raleway;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.navigationButtonsDelimiter {
  font-family: 'Raleway', sans-serif;
  font-size: 10px;
  margin-right: 10px;
}

.facebookIcon {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 430px) {
  .contentWrapper {
    flex-direction: column;
  }

  .left {
    flex-direction: row;
    padding: 10px;
    height: 40px;
    width: 90%;
    align-items: center;
    margin: 0;
  }

  .middle {
    flex-direction: column;
    margin-top: 0;
  }

  .right {
    padding: 0;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
    height: 13%;
    width: 90%;
  }

  .navigationButton {
    margin: 0;
  }

  .navigationButtonsDelimiter {
    margin: 0;
  }

  .titles {
    padding: 20px;
    margin-top: 20px;
  }

  .title {
    font-size: x-large;
  }

  .navigationButtonsContainer {
    margin-bottom: 0;
  }
}

.slideAnimation {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}